import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import axios from 'axios';
import cheerio from 'cheerio';
import {
  Grid,
  Image,
  Container,
  Responsive,
} from 'semantic-ui-react';
import { SectionHeader, SectionDescription } from './WhySegment';
import Carousel from 'nuka-carousel';

import image1 from '../static/images/gallery1.jpg';
import image2 from '../static/images/gallery2.jpg';
import image3 from '../static/images/gallery3.jpg';
import image4 from '../static/images/gallery4.jpg';
import image5 from '../static/images/gallery5.jpg';
import image6 from '../static/images/gallery6.jpg';

import {
  MAX_MOBILE, MIN_MOBILE
} from '../constants';

const CommunityImage = styled(Image)`
  height: 375px;
  margin-right: 20px;
  background-size: cover;
  object-fit: cover;
  object-position: top;

  @media (min-width: ${MAX_MOBILE}px) {
    height: 525px;
  }

   @media (min-width: 1000px) {
    height: 825px;
  }
`;

const InstaFeedFromUser = styled(({ className, username }) => {
  const [instaFeed, $instaFeed] = React.useState([]);
  React.useEffect(() => {
    scrapingInstagramPosts({ username }).then((feed) => {
      $instaFeed(feed);
    });
  });
  return (
    <Grid className={className} columns={4}>
      {_.castArray(instaFeed || []).map(feed => {
        const thumbnail = _.get(feed, 'thumbnail_resources.1.src');
        const altText = _.get(feed, 'accessibility_caption');
        const postUrl = _.get(feed, 'post_url');
        return (
          <Grid.Column key={feed.id} as="a" href={postUrl} target="_blank">
            <Image className="thumbnail" src={thumbnail} alt={altText}/>
          </Grid.Column>
        )
      })}
    </Grid>
  )
})`
  .thumbnail {
    width: 100%;
    object-fit: cover;
  }
`;

const parseResponse = response => {
  const $ = cheerio.load(response.data)
  const scripts = $(`html > body > script`)
  // Code smells #40 and #42
  // I should verify why i get the script before the body tag
  let id = 0
  if (scripts.get(0).attribs.type === `application/ld+json`) {
    id = 1
  }
  const jsonData = $(`html > body > script`)
    .get(id)
    .children[0].data.replace(/window\._sharedData\s?=\s?{/, `{`)
    .replace(/;$/g, ``)
  return JSON.parse(jsonData).entry_data
}

async function scrapingInstagramPosts({ username }) {
  return axios
    .get(`https://www.instagram.com/${username}/`)
    .then(response => {
      const data = parseResponse(response)
      const feed = _.take(_.map(_.castArray(_.get(data, 'ProfilePage.0.graphql.user.edge_owner_to_timeline_media.edges', [])), (item) => {
        return {
          ..._.get(item, 'node'),
          post_url: `https://www.instagram.com/p/${_.get(item, 'node.shortcode')}/`
        }
      }), 8);
      return feed;
    })
    .catch(err => {
      console.warn(`\nCould not fetch instagram posts. Error status ${err}`)
      return null
    })
};

const CommunityImages = styled(({
  screenshots=[
    {
      src: image2,
      alt: 'image2',
    },
    {
      src: image3,
      alt: 'image3',
    },
    {
      src: image4,
      alt: 'image4',
    },
    {
      src: image5,
      alt: 'image5',
    },
    {
      src: image6,
      alt: 'image6',
    },
    {
      src: image1,
      alt: 'image1',
    },
  ],
  className,
}) => {
  return (
    <React.Fragment>
      <Responsive as={React.Fragment} minWidth={MIN_MOBILE}>
        <Carousel
          slidesToShow={1.4}
          wrapAround
          className={className}
          cellAlign="center"
        >
          {
            screenshots.map((item, index) => {
              return <CommunityImage key={index} {...item} />
            })
          }
        </Carousel>
      </Responsive>
      <Responsive as={React.Fragment} maxWidth={MAX_MOBILE}>
        <Carousel
          slidesToShow={1.2}
          wrapAround
          className={className}
          cellAlign="center"
        >
          {
            screenshots.map((item, index) => {
              return <CommunityImage key={index} {...item} />
            })
          }
        </Carousel>
      </Responsive>
    </React.Fragment>
  );
})`
  & .slider-list {
    .slider-slide {
      padding: 0 20px !important;
      @media (max-width: ${MAX_MOBILE}px) {
        padding: 0 5px !important;
      }
    }
  }
  & .slider-control-bottomcenter {
    bottom: -60px !important;
    ul {
      display: flex;
    }
  }
  & .slider-control-centerleft, .slider-control-centerright {
    display: none;
  }
  margin-top: 70px;
  width: 100%;
`;

const CommunitySegment = ({
  text = [
    `Our community`,
    `The communities we serve are important to us. We’ve presented at college access workshops, helped out at college fairs, mentored at hackathons, and pitched at South by Southwest. Contact us to partner with us or inform us of interesting community events!`
  ],
  screenshots,
  showScreenShots = false,
  instaUsername = 'bestfit_app',
}) => (
    <Container style={{ padding: '8em 0em' }} >
      <Grid container centered stackable verticalAlign='middle'>
        <Grid.Row>
            <SectionHeader as='h2' clr='#CD5B49'>
              {text[0]}
            </SectionHeader>
        </Grid.Row>

        <Grid.Row>
            <SectionDescription>
              {text[1]}
            </SectionDescription>
        </Grid.Row>

      {
        showScreenShots && 
        <Grid.Row>
          <CommunityImages screenshots={screenshots} />
        </Grid.Row>
      }
        <Grid.Row>
          <InstaFeedFromUser username={instaUsername} />
        </Grid.Row>
      </Grid>
    </Container>
  );

export default CommunitySegment;