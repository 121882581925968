import React from 'react'
import Helmet from 'react-helmet'
import _ from 'lodash'

import MissionSegment from '../components/MissionSegment';
import StorySegment from '../components/StorySegment';
import TeamSegment from '../components/TeamSegment';
import CommunitySegment from '../components/CommunitySegment';
import ContactUsSegment from '../components/ContactUsSegment';

const getOneOf = (obj, paths, def) => {
  const foundPath = _.find(_.castArray(paths || []), (path) => _.has(obj, path));
  return foundPath ? _.get(obj, foundPath) : def;
}

export const AboutPageTemplate = ({ post }) => {
  return (
    <div>
      <Helmet>
        <title>About Us</title>
        <link rel="canonical" href="https://best-fit.app/about" />
      </Helmet>
      <StorySegment
        text={[
          _.get(post, 'frontmatter.storySegment.headerText'),
          _.get(post, 'frontmatter.storySegment.descriptionText'),
          _.get(post, 'frontmatter.storySegment.detailText')
        ]}
        screenshots={_.castArray(_.get(post, 'frontmatter.storySegment.images', []).map(image => ({
          src: getOneOf(image, ['src.childImageSharp.fluid.src', 'src.publicURL', 'src']),
          title: _.get(image, 'title')
        })))}
      />
      <MissionSegment
        text={[
          _.get(post, 'frontmatter.missionSegment.headerText'),
          _.get(post, 'frontmatter.missionSegment.descriptionText'),
          _.get(post, 'frontmatter.missionSegment.detailText')
        ]}
        screenshots={_.castArray(_.get(post, 'frontmatter.missionSegment.images', []).map(image => ({
          src: getOneOf(image, ['src.childImageSharp.fluid.src', 'src.publicURL', 'src']),
          title: _.get(image, 'title')
        })))}
      />
      <TeamSegment
        text={[
          _.get(post, 'frontmatter.communitySegment.headerText'),
          _.get(post, 'frontmatter.communitySegment.descriptionText')
        ]}
        members={_.castArray(_.get(post, 'frontmatter.teamSegment.members', []).map(member => ({
          ...member,
          image: {
            src: getOneOf(member, ['image.src.childImageSharp.fluid.src', 'image.src.publicURL', 'image.src']),
            title: _.get(member, 'image.title')  
          }
        })))}
      
      />
      <CommunitySegment
        text={[
          _.get(post, 'frontmatter.communitySegment.headerText'),
          _.get(post, 'frontmatter.communitySegment.descriptionText')
        ]}
        screenshots={_.castArray(_.get(post, 'frontmatter.communitySegment.images', []).map(image => ({
          src: getOneOf(image, ['src.childImageSharp.fluid.src', 'src.publicURL', 'src']),
          alt: _.get(image, 'title')
        })))}
      />
      <ContactUsSegment />
    </div>
  )
}

export default AboutPageTemplate;
