import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import AboutLayout from '../components/AboutLayout';

import { HTMLContent } from '../components/Content'

import AboutPageTemplate from '../components/AboutPageTemplate'

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <AboutLayout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        post={post}
      />
    </AboutLayout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        storySegment {
          images {
            src {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            title
          }
          headerText
          descriptionText
        }
        missionSegment {
          images {
            src {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            title
          }
          headerText
          descriptionText
          detailText
        }
        teamSegment {
          members {
            image {
              src {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
                publicURL
              }
              title
            }
            name
            title
            bioInfo {
              name
              value
            }
            contact {
              type
              value
            }
          }
        }
        communitySegment {
          images {
            src {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            title
          }
          headerText
          descriptionText
        }
      }
    }
  }
`

export default AboutPage;
