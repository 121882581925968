import React from 'react'

// import 'semantic-ui-less/semantic.less';
import ScrollUpButton from 'react-scroll-up-button';

import './styles';

import BFAboutHeader from './BFAboutHeader';
import Footer from './Footer';
import Meta from './Meta';


export const AboutLayout = ({ children, ...props }) => (
  <div>
    <Meta/>
    <BFAboutHeader />
    {children}
    <Footer />
    <ScrollUpButton />
  </div>
);

export default AboutLayout
