import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {
  Grid,
  Header,
  Image,
  Container,
  Divider
} from 'semantic-ui-react';
import { SectionHeader, SectionDescription } from './WhySegment';
import MissionImage from '../static/images/mission.svg';

const SectionFact = styled.p`
  font-size: 14px;
  
  @media (min-width: 700px) {
    font-size: 16px;
  }
`;

const FactHeader = styled(Header)`
  color: ${props => props.clr};
  margin-bottom: 0px;

  @media (min-width: 700px) {
    font-size: 50px;
  }
`;
const MissionSegment = ({
  text = [
    `Our mission`,
    `Our mission is to close the gap in college decision-making and create a clearer, more defined path to college and college graduation. First-generation, underrepresented minority, and low-income students have lower graduation rates not because they lack the ability, but because they face systemic barriers in accessing the support and insights of a college-going network.${`\n\n`}We start by empowering students with higher-quality data and advice to decide where to spend their next few years. By attending a college that will support their success, we hope that they will be more likely to graduate and reap the benefits of a college degree.${`\n\n`}We envision a world where colleges emphasize programs & services over rankings, experiences of real students over diversity statistics, and student outcomes over prestige.`,
    `of low-income, minority, and first-generation students who attend college won’t graduate.`
  ],
  screenshots = [
    {
      src: MissionImage,
      title: '75% of students',
    }
  ],
}) => (
    <Container style={{ padding: '2em 0em 8em 0em' }}>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row style={{ justifyContent: 'center' }}>

          <Grid.Column width={10}>
            <SectionHeader as='h2' clr='#CD5B49'>
              {text[0]}
            </SectionHeader>

            <SectionDescription style={{ whiteSpace: 'pre-line' }}>
              {text[1]}
            </SectionDescription>
          </Grid.Column>

          <Grid.Column width={5} floated='right'>
            <Image size='medium' src={_.get(screenshots, '0.src')} alt={_.get(screenshots, '0.title')}/>

            <Divider section />

            <FactHeader as='h2' clr='#CD5B49'>
              75%
            </FactHeader>

            <SectionFact>
              {text[2]}
            </SectionFact>
          </Grid.Column>

        </Grid.Row>
      </Grid>
    </Container>
  );

export default MissionSegment;