import React from 'react';
import styled from 'styled-components';
import {
  Grid,
  Container,
} from 'semantic-ui-react';
import { SectionHeader, SectionDescription } from './WhySegment';
import HubspotForm from 'react-hubspot-form';

const ContactForm = styled.div`
  iframe {
    @media (min-width: 400px) {
      width: 400px !important;
    }

    @media (min-width: 787px) {
      width: 750px !important;
    }
  }

`
const ContactUsSegment = ({
  text = [
    `Contact us`,
    `Reach out for questions, opportunities, or just to say hi!`
  ],
}) => (
    <Container style={{ padding: '8em 0em' }} >
      <Grid container centered stackable verticalAlign='middle'>
        <Grid.Row>
          <SectionHeader as='h2' clr='#CD5B49'>
            {text[0]}
          </SectionHeader>
        </Grid.Row>

        <Grid.Row>
          <SectionDescription>
            {text[1]}
          </SectionDescription>
        </Grid.Row>

        <Grid.Row centered>
          <ContactForm id="contact">
            <HubspotForm
              portalId='4359972'
              formId='0b7a7911-1f13-4afa-8ef7-a6975584b079'
              onSubmit={() => console.log('Submit!')}
              onReady={(form) => console.log('Form ready!')}
              loading={<div>Loading...</div>}
              cssClass='contactForm'
            />
          </ContactForm>
        </Grid.Row>
      </Grid>
    </Container>
  );

export default ContactUsSegment;