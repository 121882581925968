import React from 'react';
import { Container, Grid, Image } from 'semantic-ui-react';
import _ from 'lodash';

import styled from 'styled-components';
import { SectionHeader, SectionDescription } from './WhySegment';
import AboutOne from '../static/images/about-1.svg';
import AboutTwo from '../static/images/about-2.svg';
import AboutThree from '../static/images/about-3.svg';

const StorySegment = styled(({
  text = [
    `Our story`,
    `BestFit started in Fall 2017 as a research project while Asha and Becca were both graduate students at Teachers College, Columbia University. We bonded over our passion for education, our love for late-night $5.99 Domino’s Pizza, as well as the fact that we both attended colleges outside of our home state or country without having visited them.${`\n\n`}Our research revealed that we were not alone: College can range from the cost of a car to the price of a home, but many people invest in it essentially sight unseen. This is why we designed BestFit as a decision-making tool: to help students fully understand how school choice can impact outcomes, and be able to identify colleges that can provide them with the support and environment they need to succeed.${`\n\n`}— Asha & Rebecca`
  ],
  screenshots = [
    {
      src: AboutOne,
      title: 'About us graphic one',
    },
    {
      src: AboutTwo,
      title: 'About us graphic two',
    },
    {
      src: AboutThree,
      title: 'About us graphic three',
    }
  ],
  className,
}) => (
  <div className={className} >
    <Container>
      <Grid stackable centered verticalAlign='middle'>
        <Grid.Row only='mobile'>
          <Grid.Column>
            <Image src={_.get(screenshots, '0.src')} floated='left' size='tiny' alt={_.get(screenshots, '0.title')} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered style={{ justifyContent: 'center', textAlign: 'center' }}>
          <Grid.Column >
            <SectionHeader as='h3' clr='#CD5B49' style={{
              textAlign: 'center'}}>
                {text[0]}
              </SectionHeader>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row only='mobile'>
          <Grid.Column width={8}>
            <SectionDescription style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
              {text[1]}
            </SectionDescription>
          </Grid.Column>
          
          <Grid.Column >
              <Image src={_.get(screenshots, '1.src')} floated='right' size='tiny' alt={_.get(screenshots, '1.title')}/>
          </Grid.Column>
        </Grid.Row>    
        
        <Grid.Row only='tablet computer'>
          <Grid.Column width={3}>
            <Image floated='left' src={_.get(screenshots, '0.src')} size='medium' style={{ marginBottom: '150px' }} alt={_.get(screenshots, '0.title')}/>
            <Image floated='right' src={_.get(screenshots, '1.src')} size='medium' alt={_.get(screenshots, '1.title')} />
          </Grid.Column>

          <Grid.Column width={9}>
            <SectionDescription style={{ whiteSpace: 'pre-line', textAlign: 'center', margin: '0em 2em' }}>
              {text[1]}
            </SectionDescription>
          </Grid.Column>

          <Grid.Column width={3}>
              <Image src={_.get(screenshots, '2.src')} floated='left' size='medium' alt={_.get(screenshots, '2.title')}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </div>
))`
  background-color: transparent;
  padding-bottom: 80px;
`;

export default StorySegment;