import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {
  Grid,
  Image,
  Segment,
  Divider,
  Icon
} from 'semantic-ui-react';
import { SectionHeader } from './WhySegment';

const TeamName = styled.h5`
  font-size: 20px;
  background-color: #FFDB5C;
  padding: 0.2em 0.2em !important;
  max-width: 175px;
  text-align: center;

  
  @media (min-width: 700px) {
    font-size: 30px;
  }
`;

const SectionFact = styled.p`
  font-size: 14px;
  
  @media (min-width: 700px) {
    font-size: 16px;
  }
`;

const BioSegment = ({
  bioInfo, ...props
}) => (
  <Grid.Column width={6} floated={props.floatVar} textAlign={props.alignVar} >
    {
      _.castArray(bioInfo || [])
      .map(({ name, value }, index) => (
        <SectionFact key={index}><b>{name}</b><br />{value}</SectionFact>    
      ))
    }
  </Grid.Column>
);

const LinkedInContact = ({ value }) => {
  return (
    <a href={value}>
      <Icon name='linkedin' size='large' style={{ color: '#424242' }}/>
    </a>
  );
};

const ContactSegment = ({ contact }) => {
  const contactTypes = {
    linkedin: LinkedInContact
  }
  return (
    <>
      {
        _.castArray(contact || []).map((item, index) => {
          const { type } = item;
          const Component = _.get(contactTypes, type);
          if(Component) {
            return <Component key={index} {...item} />
          }
          return null;
        })
      }
    </>
  )
};

const StyledDivider = styled(Divider)`
  width: 20px !important;
  display: inline-block;
`;

const TeamSegment = styled(({
  members,
  className
}) => (
  <Segment className={className} style={{ padding: '5.7em 0em', background: 'rgba(248, 160, 85, .85)', color: '#424242' }} vertical>
    <Grid container centered stackable verticalAlign='middle' >
      <Grid.Row>
        <SectionHeader as='h2' clr='#424242'>
          Our team
        </SectionHeader>
      </Grid.Row>

      <Grid.Row >
        {
          members.map((member, index) => {
            const even = !!(index % 2);
            return (
              <Grid.Column key={index} width={6} floated={even ? 'right' : 'left'} textAlign={even ? 'right' : 'left'}>
                {
                  even ?
                    <Image src={_.get(member, 'image.src')} floated='right'/> :
                    <Image src={_.get(member, 'image.src')} />
                }
                {
                  even ?
                    <TeamName style={{ float: 'right', clear: 'both' }} >{_.get(member, 'name')}</TeamName> :
                    <TeamName >{_.get(member, 'name')}</TeamName>
                }
                {
                  even ?
                    <SectionFact style={{ clear: 'both' }}>{_.get(member, 'title')}</SectionFact>:
                    <SectionFact>{_.get(member, 'title')}</SectionFact>
                }
                <BioSegment
                  floatVar={ even ? 'right' : 'left' }
                  alignVar={ even ? 'right' : 'left' }
                  bioInfo={_.get(member, 'bioInfo')} maxWidth={787}
                />
                <div className="links-mobile">
                  <div >
                    <StyledDivider style={{ width: '20px !important' }} />
                  </div>
                  <ContactSegment contact={_.get(member, 'contact')} />
                </div>
              </Grid.Column>
            );
          })
        }
      </Grid.Row>
      <Grid.Row >
        {
          members.map((member, index) => {
            const even = !!(index % 2);
            return (
              <Grid.Column className="links-tablet" key={index} width={6} floated={even ? 'right' : 'left'} textAlign={even ? 'right' : 'left'}>
                <div>
                  <StyledDivider style={{ width: '20px !important' }} />
                </div>
                <ContactSegment contact={_.get(member, 'contact')} />
              </Grid.Column>
            );
          })
        }
      </Grid.Row>
    </Grid>
  </Segment>
))`
  .links-mobile {
    display: none;
    @media (max-width: 600px) {
      display: block !important;
    }
  }
  .links-tablet {
    @media (max-width: 600px) {
      display: none !important;
    }
  }
`;

export default TeamSegment;
